import { Injectable } from '@angular/core';
import { Storage } from '../storage/domain/storage';
import { Nullable } from '../common/domain/types/types';
import * as moment from 'moment-timezone';
import * as semver from 'semver';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  static readonly APP_VERSION: string = '1.12.2';
  static readonly KEY_CURRENT_VERSION = 'system.current_version';
  static readonly KEY_LAST_NEW_VERSION_REMEMBER_TIME = 'system.last_new_version_remember_time';

  // Use this only when it is really needed
  static readonly MINIMUM_APP_VERSION_THAT_FORCES_RESET_STORAGE: string = '1.12.1';

  constructor(private readonly storage: Storage) {}

  async getCurrentStoredAppVersion(): Promise<Nullable<string>> {
    return (await this.storage.get(SystemService.KEY_CURRENT_VERSION)) ?? null;
  }

  async getNewVersionAvailableLastRememberTime(): Promise<Nullable<string>> {
    return (await this.storage.get(SystemService.KEY_LAST_NEW_VERSION_REMEMBER_TIME)) ?? null;
  }

  async updateNewVersionAvailableLastRememberTime(): Promise<void> {
    const now = moment().format();
    await this.storage.set(SystemService.KEY_LAST_NEW_VERSION_REMEMBER_TIME, now);
  }

  async clearSessionRelatedData() {
    await this.storage.remove(SystemService.KEY_LAST_NEW_VERSION_REMEMBER_TIME);
  }

  async resetStorage() {
    const needsToBeReset = await this.storageNeedsToBeReset();
    if (!needsToBeReset) {
      return;
    }

    await this.storage.reset();
    await this.storeCurrentVersionToStorage();
  }

  async storeCurrentVersionToStorage() {
    await this.storage.set(SystemService.KEY_CURRENT_VERSION, SystemService.APP_VERSION);
  }

  async storageNeedsToBeReset(): Promise<boolean> {
    const currentStoredAppVersion = await this.getCurrentStoredAppVersion();

    // It is a new install
    if (!currentStoredAppVersion) {
      return false;
    }

    return semver.gt(SystemService.MINIMUM_APP_VERSION_THAT_FORCES_RESET_STORAGE, currentStoredAppVersion);
  }
}
